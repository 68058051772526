import { decrypt, encrypt } from "./crypt"

export const getStorage = (key = 'root') => {
  const storage = window.localStorage.getItem(key)
  return storage === null ? undefined : JSON.parse(storage)
}

export const saveStorage = ({ key, value }) => {
  window.localStorage.setItem(key || 'root', JSON.stringify(value))
}

export const getSecureStorage = (key = 'root') => {
  const storage = window.localStorage.getItem(key)
  return storage === null ? undefined : decrypt(storage)
}

export const saveSecureStorage = ({ key, value }) => {
  const storage = encrypt(value)
  window.localStorage.setItem(key, storage)
}

export const removeStorage = (key = 'root') => {
  window.localStorage.removeItem(key)
}

export const clearStorage = () => {
  window.localStorage.clear()
}
