import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { NewInput } from '../NewInput'
import Icon from 'react-icons-kit'
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down'
import { ic_clear } from 'react-icons-kit/md/ic_clear'
import { Popover } from '@mui/material'
import {
  AutocompleteInputContainer,
  ErrorText,
  PopoverContainer,
  useStyles
} from './styles'
import { useRef } from 'react'
import { useEffect } from 'react'

export const Autocomplete = ({
  name,
  options = [],
  onChange,
  loading = false,
  asyncFilter,
  getMore = () => null,
  error = '',
  noMore = true,
  disabled = false,
  renderOption,
  renderOptionComponent,
  selectedValue = {},
  optionKeyName,
  uniqueKeyName,
  clear,
  register,
  required
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [internalOptions, setInternalOptions] = useState(() => options)
  const [inputValue, setInputValue] = useState('')
  const inputeRef = useRef(null)
  const containerRef = useRef(null)

  const styles = useStyles()()

  useEffect(() => setInternalOptions(options), [options])
  useEffect(() => {
    if (!anchorEl) {
      const { current } = inputeRef
      current && current.blur()
    }
  }, [anchorEl])
  const handleSetInputValue = (event) => {
    if (event?.target?.value || event?.target?.value === '') {
      setInputValue(event.target.value)
      if (asyncFilter) {
        asyncFilter(name, event.target.value)
      } else {
        const _options = options.filter((option) =>
          option[optionKeyName]
            .toLowerCase()
            .includes(
              event.target.value ? event.target.value.toLowerCase() : ''
            )
        )
        setInternalOptions(_options)
      }
    } else {
    }
  }

  const handleOnclick = (event) => setAnchorEl(event.currentTarget)

  const handleOnClose = () => {
    setAnchorEl(null)
    if (asyncFilter) {
      asyncFilter(name, '')
    } else {
      setInternalOptions([])
    }
  }

  const handleSelected = (id) => {
    let _selected = null
    internalOptions.forEach((option) => {
      if (option[uniqueKeyName] === id) {
        _selected = option
      }
    })
    onChange && onChange(_selected)
    handleOnClose()
  }
  const handleClear = (event) => {
    event.stopPropagation()
    const value = {}
    onChange(value)
    clear && clear(value)
  }

  const isSelectedValueEmpty = () => Boolean(Object.keys(selectedValue).length)
  const getValue = () =>
    isSelectedValueEmpty()
      ? renderOption
        ? renderOption(selectedValue)
        : selectedValue[optionKeyName]
      : ''
  const _value = getValue()
  const requiredProp = register(name, { required })
  return (
    <>
      <AutocompleteInputContainer
        className="autocomplete-input-container"
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        }}
        ref={containerRef}
        onClick={handleOnclick}
      >
        <NewInput
          className="autocomplete-input"
          value={_value}
          onChange={handleSetInputValue}
          disabled={disabled}
          title={_value || undefined}
          {...requiredProp}
        />
        {clear && isSelectedValueEmpty() && (
          <div
            style={{
              width: 12,
              height: 12,
              position: 'absolute',
              right: 30,
              marginTop: -2
            }}
            onClick={handleClear}
          >
            <Icon icon={ic_clear} style={{ fill: 'black' }} />
          </div>
        )}

        <div style={{ width: 12, height: 12, position: 'absolute', right: 10 }}>
          <Icon icon={ic_keyboard_arrow_down} />
        </div>
      </AutocompleteInputContainer>
      <ErrorText text={error} />
      <Popover
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        keepMounted={false}
        className={styles.customPopover}
      >
        <PopoverContainer
          style={{ minWidth: containerRef?.current?.clientWidth || 'inherit' }}
        >
          <div style={{ marginBottom: 10 }}>
            <NewInput value={inputValue} onChange={handleSetInputValue} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}></div>
          {(loading || !internalOptions.length) && (
            <div
              className="general-loading-container"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <p>
                {!internalOptions.length && !loading
                  ? 'No options'
                  : loading
                  ? 'Loading...'
                  : ''}
              </p>
            </div>
          )}
          {!loading && (
            <div
              className={styles.list}
              style={{
                maxHeight: 220,
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={getMore}
                hasMore={!noMore}
                threshold={10}
                loader={
                  !noMore ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      key={0}
                    >
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <></>
                  )
                }
                useWindow={false}
              >
                <div />
                {internalOptions.map((option) => (
                  <div
                    className={styles.item}
                    key={option[uniqueKeyName]}
                    onClick={() => handleSelected(option[uniqueKeyName])}
                    style={{
                      display: 'flex',
                      backgroundColor: 'transparent'
                    }}
                  >
                    {renderOptionComponent ? (
                      renderOptionComponent(option)
                    ) : (
                      <p>
                        {renderOption
                          ? renderOption(option)
                          : option[optionKeyName] || option}
                      </p>
                    )}
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </PopoverContainer>
      </Popover>
    </>
  )
}
