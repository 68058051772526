import { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { Fetch } from '../utils/fetch'

export const useSearchableData = (
  url,
  param ,
  options  = { time: 700, extraParams: '', disabled: false },
) => {
  const { time, requestOptions, extraParams, disabled, customErrorMessage } = options
  const timeoutRef = useRef(null)
  const mountedRef = useRef(false)
  const [response, setResponse] = useState({
    data: [],
    loading: true,
    error: '',
  })

  const [fetch, setFetch] = useState({
    noMore: false,
    loadMore: false,
    page: 1,
    keyword: '',
  })

  const { page, keyword, loadMore, noMore } = fetch

  const setKeyword = useCallback((keyword) => setFetch((prev) => ({ ...prev, keyword, page: 1 })), [])

  const getNextPage = useCallback(
    () => !loadMore && !noMore && setFetch((prev) => ({ ...prev, page: prev.page + 1, loadMore: true })),
    [loadMore, noMore],
  )

  useEffect(() => {
    
    setResponse((prev) => ({ ...prev, loading: page === 1, error: '' }))
    mountedRef.current = true
    const getData = () => {
      
      if (!disabled) {
        
        Fetch(
          'get',
          `${url}?limit=20&page=${page}${keyword ? '&' + param + '=' + keyword : ''}${extraParams || ''}`,
          requestOptions,
        )
          .then(({ data = [] }) => {
            if (mountedRef.current) {
              setResponse((prev) => ({
                ...prev,
                data: page === 1 ? data : [...prev.data, ...data],
                loading: false,
                error: '',
              }))
              setFetch((prev) => ({
                ...prev,
                loadMore: false,
                noMore: data.length < 20,
                error: '',
              }))
            }
          })
          .catch(() => {
            if (mountedRef.current) {
              setResponse({
                data: [],
                loading: false,
                error: customErrorMessage || "Options couldn't be loaded",
              })

              setFetch((prev) => ({
                ...prev,
                loadMore: false,
                noMore: true,
              }))
            }
          })
      }
    }

    timeoutRef.current && clearInterval(timeoutRef.current)

    timeoutRef.current = setTimeout(() => getData(), time)
    return () => {
      mountedRef.current = false
    }
  }, [keyword, param, url, page, time, requestOptions, extraParams, disabled, customErrorMessage])

  return {
    ...response,
    setKeyword,
    getNextPage,
    ...fetch,
  }
}