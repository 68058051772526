import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Textarea = styled.textarea`
  font-size: 16px;
  padding: 11px;
  display: block;
  width: 100%;
  color: ${themeGet('colors.textColor', '#484848')};
  box-shadow: none;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${themeGet('colors.inactiveIcon', '#ebebeb')};
  transition: border-color 0.2s ease;
  &:focus {
    outline: none;
    border-color: ${themeGet('colors.primary', '#147efb')};
  }
  min-height: 150px;
`
