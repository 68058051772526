import axios from 'axios'
import { getSecureStorage } from '../utils/storage'
const BACKEND_URL = process.env.GATSBY_BACKEND_BASE_URL
const ERROR_MESSAGE = 'Something went wrong. \nPlease contact support'
export const Fetch = async (method, enpoint, options = {}) => {
  const {
    body,
    authorization,
    externalUrl,
    contentType = 'application/json',
    apiKey
  } = options

  const url = externalUrl ? `${externalUrl}${enpoint}` : `${BACKEND_URL}${enpoint}`
  const auth = getSecureStorage('auth')
  const Authorization =
    authorization || authorization === ''
      ? authorization
      : `Bearer ${auth || null}`
  const headers = {
    'content-type': contentType,
    Authorization
  }
  if (apiKey) {
    headers['x-api-key'] = apiKey
  }
  return axios({
    url,
    headers,
    method,
    data: body,
    timeout: options.timeout || 10000,
    timeoutErrorMessage: ERROR_MESSAGE
  })
    .then((res) => {
      return res?.data || {}
    })
    .catch((response) => {
      if (!response?.response?.data) {
        return Promise.reject({ message: ERROR_MESSAGE })
      }
      if (
        response?.response?.data?.code === 'TOKEN_EXPIRED' ||
        response?.response?.data?.code === 'invalid_token' ||
        response?.response?.data?.code === 'USER_NOT_FOUND'
      ) {
        // forced logout logic goes here
      }
      const { code, message } = response?.response?.data || {}
      return Promise.reject({
        code,
        message
      })
    })
}
