import React from 'react'
import PropTypes from 'prop-types'
import { Datepicker } from './styles'
import { DatePicker } from '@material-ui/pickers'

export const CustomDatePicker = ({ value, onChange, name }) => {
  return (
    <DatePicker
      label="Basic example"
      value={value || null}
      onChange={onChange}
      animateYearScrolling
      disableToolbar={true}
      TextFieldComponent={Datepicker}
      format="MM/DD/YYYY"
    />
  )
}

CustomDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string
}
