import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const ErrorText = styled.p`
  color: #c00;
  font-size: 10px;
  visibility: ${(props) => (props.text ? 'visible' : 'hidden')};
`

export const PopoverContainer = styled.div`
  .general-loading-container {
    p {
      margin: 0;
    }
  }
`

export const AutocompleteInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .autocomplete-input {
    padding-right: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const useStyles = (options) =>
  makeStyles(() => ({
    input: {
      cursor: 'pointer',
      boxSizing: 'border-box',
      display: 'flex',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      height: '37.28px',
      alignItems: 'center',
      padding: '10.5px 14px',
      '& > p': {
        flex: 1
      }
    },
    icon: {
      marginRight: '10px',
      display: 'flex',
      alignItems: 'center'
    },
    popover: {
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: '10px 10px 0 0',
      marginTop: '-52px',
      minWidth: '250px',
      maxWidth: '250px'
    },
    autocomplete: {
      '& + .MuiAutocomplete-popper': {
        width: '100% !important',
        minWidth: '250px',
        maxWidth: '250px',
        '& > .MuiPaper-root': {
          margin: '0',
          boxShadow:
            '0px 2px 1px -1px rgba(85, 48, 48, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '0 0 10px 10px'
        },
        '.Checkbox-container': {
          '.MuiTypography-body1 ': {
            fontSize: '13px'
          }
        }
      }
    },
    customPopover: {
      '& .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
        padding: ' 20px 10px',
        marginLeft: '0px',
        marginTop: '-3px',
        minWidth: ' 250px',
        maxWidth: 'inherit',
        width: 'auto',
        boxSizing: 'border-box',
        borderRadius: '10px'
      }
    },
    list: {
      '&::-webkit-scrollbar': {
        width: ' 8px',
        height: '8px'
        // display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#b3b3b3',
        boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)'
      },
      '::-webkit-scrollbar-thumb:active': {
        backgroundColor: '#999999'
      }
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '7px 0',
      transition: 'background .3s ease',
      '& > p': {
        margin: 0,
        fontSize: 12
      },
      '&:hover': {
        backgroundColor: '#f4f4f4'
      },
      '& > .MuiFormControlLabel-root': {
        marginLeft: 0
      },
      '& .MuiCheckbox-root': {
        padding: '3px'
      }
    },
    addNewEl: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 5,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f4f4f4'
      }
    }
  }))
